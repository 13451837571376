<template>
  <vx-card class="p-4">
    <form action="">
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('fields.username') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <vs-input v-validate="'required'" class="w-full" name="username" v-model="operator.username" placeholder="User name" />
          <span class="text-danger text-sm" v-show="errors.has('username')">{{ errors.first('username') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('fields.name') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <div class="vx-col my-2 w-full">
            <vs-input v-validate="'required'" class="w-full" name="name_tm" :placeholder="$t('fields.name')" v-model="operator.name" />
            <span class="text-danger text-sm" v-show="errors.has('name_tm')">{{ errors.first('name_tm') }}</span>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('fields.phone') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <div class="vx-col my-2 w-full">
            <vs-input v-validate="'required'" class="w-full" name="phone" :placeholder="$t('fields.phone')" v-model="operator.phone" />
            <span class="text-danger text-sm" v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('fields.email') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <vs-input v-validate="'required|email'" name="email" class="w-full" v-model="operator.email" />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('password') | capitalize }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <vs-input v-validate="'required'" name="password" class="w-full" type="password" v-model="operator.password" />
          <span class="text-danger text-sm" v-show="errors.has('password')">{{ errors.first('password') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('role') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <v-select v-validate="'required'" :options="roles" :label="`name_${$i18n.locale}`" name="user_role" :placeholder="$t('selectOne')" v-model="operator.role" :reduce="opt => opt.value" />
          <span class="text-danger text-sm" v-show="errors.has('user_role')">{{ errors.first('user_role') }}</span>
        </div>
      </div>
      <!-- <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">Is Express:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <li class="vx-col my-2 ml-2 mr-4 flex">
            <vs-switch name="isBulk" v-model="operator.isExpress" />
            <label class="ml-3">Express operator?</label>
          </li>
        </div>
      </div> -->
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('isBlocked') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <li class="vx-col my-2 ml-2 mr-4 flex">
            <vs-switch name="isBulk" v-model="operator.isBlocked" />
            <label class="ml-3">{{ $t('block') }}?</label>
          </li>
        </div>
      </div>
      <vs-row>
        <vs-button type="filled" @click.prevent="submitForm" class="ml-auto">{{ $t('submit') }}</vs-button>
        <vs-button type="border" color="warning" @click.prevent="$router.back()" class="ml-4 mr-auto block">{{ $t('cancel') }}</vs-button>
      </vs-row>
    </form>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      operator: {},
      roles: [
        { name_en: 'Admin', name_tm: 'Admin', name_ru: 'Admin', value: 'admin'},
        { name_en: 'Superadmin', name_tm: 'Superadmin', name_ru: 'Superadmin', value: 'superadmin'},
        { name_en: 'Operator', name_tm: 'Operator', name_ru: 'Operator', value: 'operator'},
        { name_en: 'Stock operator', name_tm: 'Stock operator', name_ru: 'Stock operator', value: 'stock_operator'}
      ]
    }
  },
  methods: {
    submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.post('/admins', {
            ...this.operator
          }).then(() => {
            // if (response.status === 201) {
            this.notify(this.$t('notify.success'), `${this.$t('operator')} ${this.$t('notify.sCreated')}`, 'icon-check-circle', 'primary')
            this.$router.back()
            // }
          }).catch(err => { this.notify(this.$t('notify.error'), err.message, 'icon-alert-circle', 'danger') })
        } else { this.notify(this.$t('notify.error'), this.$t('notify.formInvalid'), 'icon-alert-circle', 'danger') }
      })
    },
    notify (title, text, icon, color) {
      this.$vs.notify({ title, text, iconPack: 'feather', icon, color })
    }
  }
}
</script>
